import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import i18next from '../i18n.js'
import ContactForm from "./contactForm"
import LinkTile from "./linkTile"
import * as Styles from "../styles/index.module.scss"

const HomeContact = ({locale}) => {
    const data = useStaticQuery(graphql`query MyOhQuery2 {
        allAirtableOfficehours {
          edges {
            node {
              data {
                Date(formatString: "D.M.YYYY")
                compareDate: Date(formatString: "YYYYMMDD")
                Time
              }
              recordId
            }
          }
        }
      }`)

    const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('')
    var { t } = i18next
    return (
            <div class={Styles.sectionInner}>
                <h2>{t('homeContactTitle')}</h2>
                <p>{t('homeContactText')}!</p>
                <div class={Styles.columns}>
                    <div class={Styles.column}>
                        <h3>{t('homeOfficehoursTitle')}</h3>
                        <p>{t('homeOfficehoursText')}</p>
                        <ul>
                            {
                                data.allAirtableOfficehours.edges.filter(edge => edge.node.data.compareDate >= currentDate)
                                    .map((edge) => (
                                        <li>
                                            {edge.node.data.Date + ' um ' + edge.node.data.Time + ' Uhr '}
                                        </li>
                                    ))
                            }
                        </ul>
                      <LinkTile to="/officehours" 
                                locale={locale}
                                text={t('homeOfficehoursBook')} ></LinkTile>
                    </div>



                    <div class={Styles.column}>
                        <h3>{t('homeContactSubTitle')}</h3>
                        <p>{t('homeContactSubText')}</p>

                        <ContactForm />
                    </div>
                </div>
            </div>
    )
}
export default HomeContact