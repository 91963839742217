import * as React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Markdown from './Markdown'
import Link from './localizedLink'
import moment from 'moment'
import {
   EventSchedule,
   Hourglass,
   Education
 } from "@carbon/icons-react";

import * as Styles from "../styles/training.module.scss"

const EventTile = ({ title, 
                     excerpt,
                     slogan,
                     slug,
                     locale,
                     cssClass,
                     duration,
                     level,
                     nextDate,
                     image }) => {
  return (
   <div class={Styles.trainingTile + ' ' + cssClass}>
      <Link to={slug}
            locale={locale}
            >
         <div class={Styles.trainingTileContent}>
    <div className={Styles.overlayArea}>
        { <GatsbyImage image={getImage(image)}
                      style={{ gridArea: "1/1"}}
                      className={Styles.gatsbyImage}/> }
         <div className={Styles.overlay}>
     
       <h3>{title}</h3>    </div>
       </div>

   <div className={Styles.iconRowArea}>
         {nextDate?(<span className={Styles.iconRow}><EventSchedule size={24}/>{new moment(nextDate).format('D.M.YYYY')} </span>):null} 
         {level?(<span className={Styles.iconRow}><Education  aria-label="Dauer" size={24}/><span>{level}</span></span>):null}
         {duration?(<span className={Styles.iconRow}><Hourglass aria-label="Dauer" size={24}/>{duration} Tage</span>):null}
         </div>
         
   <div className={Styles.trainingTileInfo}>
        <div className={Styles.slogan}>{slogan}</div>
        <hr />
        <Markdown>{excerpt}</Markdown>
       </div>
       </div>
      <button>Mehr erfahren</button>
       </Link>
    </div>
  )
}
export default EventTile 