import React from "react"
import * as Styles from "../styles/index.module.scss"
import TrainingGrid from "../components/trainingGrid"


import i18next from '../i18n.js'
const HomeTraining = ({ data, locale }) => {

  var { t } = i18next
  return (
    <section class={Styles.section3}>

        <div class={Styles.sectionInner + ' ' + Styles.trainingGrid}
          style={{ gridArea: "1/1" }}>
            
          <h2>{t('trainingPageTitle')}</h2>
          <TrainingGrid data={data} locale={locale} />
        </div>
    </section>)

}
export default HomeTraining