import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import * as Styles from "../styles/index.module.scss"
import i18next from '../i18n.js'
import Markdown from "./Markdown.js";
const HomeWelcome = () => {

  var { t } = i18next
  return (
    <section class={Styles.section2}>
      <div class={Styles.sectionInner}>
        <h2>{t('sectionIntroductionHeading')}</h2>
        <div class={Styles.columns}>
          <div class={Styles.column}>
            <StaticImage src="../images/people/j&s_jacket_zugewandt_arme_verschraenkt.jpg"
              alt="Jörg Brandeis & Sören Schlegel"
              placeholder="tracedSVG"
              layout="fullWidth"
            >

            </StaticImage>
          </div>
          <div class={Styles.column}>
            <Markdown>{t('sectionIntroductionText')}</Markdown>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeWelcome;
