import * as React from "react"

import HomeContact from "../../components/homeContact"
//import HomeIntro from "../../components/homeIntro"
import HomeTraining from "../../components/homeTraining"
import HomeWelcome from "../../components/homeWelcome"
import Layout from "../../components/layout"
import Seo from "../../components/seo";
import Testimonials from "../../components/testimonials"
import NewsSlider from "../newsSlider"
import * as Styles from "../../styles/index.module.scss"


// markup
const IndexPageTemplate = ({ pageContext, data, excerpt, title }) => {


  var translationContext = {
    locale: pageContext.locale,
    defaultMenu: true
  };
  return (
    <Layout
      pageContext={pageContext}
      translationContext={translationContext}>
      <div>
        <div class={Styles.sections}>
          <Seo title={title}
            locale={pageContext.locale}
            description={excerpt}
            imagePath='https://www.brandeis.de/favicon.png'
            pageContext={pageContext} />
<NewsSlider ></NewsSlider>
{/*<HomeIntro locale={pageContext.locale} />*/}

          <HomeWelcome locale={pageContext.locale} />

          <HomeTraining data={data} locale={pageContext.locale} />
          
          <Testimonials locale={pageContext.locale} />
          <section class={Styles.section5}>
            <HomeContact locale={pageContext.locale} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPageTemplate