// extracted by mini-css-extract-plugin
export var faIconMedium = "form2-module--fa-icon-medium--32432";
export var faIconSmall = "form2-module--fa-icon-small--0c1ba";
export var field = "form2-module--field--f7a7e";
export var form = "form2-module--form--4c892";
export var formGroup = "form2-module--form-group--87dc5";
export var green = "form2-module--green--52ec7";
export var hidden = "form2-module--hidden--f190a";
export var hideOnMobile = "form2-module--hide-on-mobile--bc8ac";
export var label = "form2-module--label--4cfa6";
export var radio = "form2-module--radio--9540e";
export var radioButtonGroup = "form2-module--radio-button-group--aa21b";
export var radiobutton = "form2-module--radiobutton--56e42";