import React from "react"

import "../styles/slick.css";
import "../styles/slick-theme.css";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as Styles from "../styles/index.module.scss"

const Testimonials = () => {

  const data = useStaticQuery(graphql`
query MyTestamonials {
  allAirtableTestimonials {
    edges {
      node {
        data {
          Locale
          Name
          Position
          Quote
          Image {
            id
            localFiles {
              childImageSharp {
                gatsbyImageData(height: 400, width: 400)
              }
            }
          }
        }
      }
    }
  }
}
`)
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };
  /*https://react-slick.neostack.com/docs/get-started*/
  return (   
    <section class={`${Styles.section4} ${Styles.hideOnMobile}`}>
      <div class={Styles.sectionInner}>
        <p>Da sind wir stolz drauf</p>
        <h2>Was unsere Kunden sagen</h2>
        <div>
          <Slider {...settings}>
            {data.allAirtableTestimonials.edges.map(edge =>  (
              <div>
                <div class={Styles.testimonial}>
                  <div  ></div>
                  <GatsbyImage image={edge.node.data.Image.localFiles[0].childImageSharp.gatsbyImageData} /> 
                  <p class={Styles.testimonialQuote}>
                    {edge.node.data.Quote}
                  </p>
                  <h3>
                    {edge.node.data.Name}
                  </h3>
                  <p>
                    {edge.node.data.Position}
                  </p>
                </div>
              </div>
            )
            )}
          </Slider>
          <p>Die Bilder sind fake, aber die Zitate zu 100% echt.</p>
        </div>
      </div>
    </section>
  )
}
export default Testimonials
