import * as React from "react";
import TrainingTile from "../components/trainingTile";
import * as Styles from "../styles/training.module.scss";
// markup
const TrainingGrid = ({ data, locale }) => {
  var [filterValue, setFilterValue] = React.useState("all");
  const filters = [
    { text: "Alle anzeigen", value: "all" },
    { text: "Analytics & BW/4HANA", value: "analytics" },
    { text: "Moderne ABAP Entwicklung", value: "abap" },
    { text: "ABAP RESTful Application Programming (RAP)", value: "rap" },
    { text: "HANA Modellierung & Entwicklung", value: "hana" },
    { text: "Benutzeroberflächen", value: "ui" },
  ];
  return (
    <>
      <style>
        {filterValue === "all" ? null: filters.map((filter) => `.${filter.value}{ display: none; } `)}
        {`.${filterValue} {  display: unset !important }`}
        
        {`.${filterValue+ "btn"} {  background-color: #0c3c60 !important; color: white; font-weight: 700;}` }
      </style>
      <h2>Wähle Dein Thema aus</h2>
      <div className={Styles.filterRow}>
        {filters.map((filter) => (
          <button
            type="radio"
            id={filter.value}
            name="filter"
            className={Styles.filterButton + ' ' + filter.value + "btn"}
            onClick={() => setFilterValue(filter.value)}
          >
            {filter.text}
          </button>
        ))}
      </div>

      <div class={Styles.trainingGrid}>
        {data?.allMdx?.nodes
          .filter((a) => a.frontmatter.Locale === locale)
          .sort((a, b) => a.frontmatter.Prio - b.frontmatter.Prio)
          .map((training) => {
            var nextDate = training.frontmatter.Events?.filter(
              (a) => new Date(a.StartDate) >= new Date()
            ).sort((a, b) => a.StartDate > b.StartDate)[0];
            return (
              <>
                <TrainingTile
                  excerpt={training.frontmatter.Excerpt}
                  slogan={training.frontmatter.Slogan}
                  title={training.frontmatter.Title}
                  slug={"/training" + training.frontmatter.Slug}
                  locale={locale}
                  cssClass={training.frontmatter.cssClass}
                  image={training.frontmatter.Image}
                  level={training.frontmatter.Level}
                  duration={training.frontmatter.Duration}
                  nextDate={nextDate?.StartDate}
                ></TrainingTile>
              </>
            );
          })}
      </div>
    </>
  );
};

export default TrainingGrid;
