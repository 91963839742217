import * as React from 'react'
import i18next from '../i18n.js'

import * as Styles from "../styles/form2.module.scss"

const ContactForm = () => {
  var { t } = i18next
  return (
        <div>
            <form action="/api/submit-contact-form" method="POST" class={Styles.form} target="hiddenFrame">
           {/* <form name="contact" method="POST" data-netlify="true"  target="hiddenFrame">*/}
                <fieldset>

                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="Name">{t('contactFormName')}
                            <div class={Styles.field}>
                                <input id="Name" name="name" type="text" placeholder="Vor- und Nachname" class="form-control input-md"  required="required" aria-required="true" />
                            </div>
                        </label>
                    </div>

                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="email">{t('contactFormEmail')}
                            <div class={Styles.field}>
                                <input id="email" name="email" type="text" placeholder="name@company.com" class="form-control input-md"  required="required" aria-required="true" />
                            </div>
                        </label>
                    </div>

                    <div class={Styles.hidden}>
                        <label class={Styles.label} for="email">Honey Pot
                            <div class={Styles.field}>
                                <input id="hp" name="hp" type="text" class="form-control input-md" placeholder="Nur von Robots auszufüllen" />
                            </div>
                        </label>
                    </div>

                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="message">{t('contactFormMessage')}
                            <div class={Styles.field}>
                                <textarea class="form-control" id="message" name="message"  ></textarea>
                            </div>
                        </label>
                    </div>
            <button >
                    Absenden
                     </button>
                </fieldset>

            </form>
            <iframe name="hiddenFrame" title="Status Message" style={{ height: '7em', border: 'none', width: '100%' }} > <b>Wir melden uns gerne bei Ihnen!</b> </iframe>

        </div>

    )
}
export default ContactForm 